<template>
  <div class="oversize-lg-modal">
    <b-modal
      :visible="show"
      size="xl"
      hide-footer
      no-close-on-backdrop
      @hidden="closeModal"
    >
      <b-row>
        <b-col sm="12">
          <b-alert
            show
            variant="primary"
          >
            <div class="alert-body">
              <div>
                <feather-icon icon="AlertCircleIcon" />
                {{ $t('FirstImportRow') }}
              </div>

              <div class="mt-25">
                <feather-icon icon="AlertCircleIcon" />
                <span v-html="$t('RequiredPhoneFormat')" />
              </div>

              <div class="mt-25">
                <feather-icon icon="AlertCircleIcon" />
                <span v-html="$t('RequiredAssignedUsersField')" />
              </div>

              <div class="my-25">
                <feather-icon icon="AlertCircleIcon" />
                <span v-html="$t('ImportTags')" />
              </div>

              <!--              <b-button-->
              <!--                class="mt-50"-->
              <!--                variant="primary"-->
              <!--                size="sm"-->
              <!--                @click="donwloadSampleXlSX"-->
              <!--              >-->
              <!--                {{ $t('DownloadSampleFile') }}-->
              <!--              </b-button>-->
            </div>
          </b-alert>

        </b-col>
        <b-col
          sm="12"
          lg="6"
        >
          <div>{{ $t('AvailableFields') }}</div>
          <draggable
            :list="fields"
            tag="div"
            draggable=".draggable-item"
            :group="{ name: 'fields', pull: 'clone', put:false }"
            class="d-flex flex-wrap mt-25"
          >
            <b-badge
              v-for="(listItem, index) in fields"
              :key="index"
              class="mr-25 mt-50 flex-grow-1 font-small-3"
              :class="!fieldsOrder.includes(listItem) ? ['draggable-item', 'cursor-pointer'] : ''"
              :variant="!fieldsOrder.includes(listItem) ? 'primary' : 'light-primary'"
              @click="pushItem(listItem)"
            >
              <span v-if="listItem.prefix">{{ $t(listItem.prefix) }}: </span>{{ $t(`${listItem.label}`) }}
            </b-badge>
          </draggable>
        </b-col>
        <!-- Selected Fields -->
        <b-col
          sm="12"
          lg="6"
          class="mt-1 mt-md-0 mb-1"
        >
          <div>
            {{ $t('SelectedFields') }}
          </div>
          <draggable
            :list="fieldsOrder"
            tag="div"
            :group="{ name: 'fields' }"
            class="d-flex flex-wrap mt-25"
          >
            <b-badge
              v-for="(listItem, index) in fieldsOrder"
              :key="index"
              class="mr-25 mt-50 badge-item flex-grow-1 font-small-3 cursor-move"
            >
              <div class="d-flex justify-content-between align-items-center">
                <div class="bg-light p-25 text-secondary rounded">
                  {{ index + 1 }}
                </div>
                <div class="mx-25">
                  <span v-if="listItem.prefix">{{ $t(listItem.prefix) }}: </span>{{ $t(`${listItem.label}`) }}
                </div>
                <feather-icon
                  icon="XIcon"
                  class="cursor-pointer font-small-3"
                  @click="fieldsOrder.splice(index, 1)"
                />
              </div>
            </b-badge>
          </draggable>
        </b-col>

        <b-col sm="12"
               class="mb-50"
        >
          <b-form-checkbox v-model="disableMainContact">
            Wyłącz tworzenie kontaktu podstawowego
          </b-form-checkbox>
        </b-col>
        <b-col sm="12"
               class="mb-50"
        >
          <b-form-checkbox v-model="disableAdditionalContact">
            Wyłącz tworzenie osoby kontaktowej
          </b-form-checkbox>
        </b-col>
        <b-col sm="12"
               class="mb-50"
        >
          <b-form-checkbox v-model="assignToAdditionalContact">
            Przypisz proces to osoby kontaktowej
          </b-form-checkbox>
        </b-col>

        <b-col sm="12">
          <sw-select :name="$t('AutomationSection.ContactThreadFunnelTypePoint')">
            <v-select
              v-model="defaultFunnel"
              :options="contactThreadFunnelTypesPoints"
            >
              <template #no-options>
                {{ $t('NoOptions') }}
              </template>
              <template #option="{ name }">
                {{ name }}
              </template>
              <template #selected-option="{ name }">
                {{ name }}
              </template>
            </v-select>
          </sw-select>
        </b-col>

        <b-col sm="12">
          <b-tabs>
            <b-tab :title="$t('Contact')">
              <b-row>
                <b-col
                  sm="12"
                  md="6"
                >
                  <sw-select :name="$t('AutomationSection.ContactStatus')">
                    <v-select
                      v-model="defaultContactStatus"
                      :options="contactStatuses"
                    >
                      <template #no-options>
                        {{ $t('NoOptions') }}
                      </template>
                      <template #option="{ name }">
                        {{ name }}
                      </template>
                      <template #selected-option="{ name }">
                        {{ name }}
                      </template>
                    </v-select>
                  </sw-select>
                </b-col>

                <b-col
                  sm="12"
                  md="6"
                >
                  <sw-select :name="$t('ContactPosition')">
                    <v-select
                      v-model="defaultPosition"
                      :options="contactPositionTypes"
                      :reduce="item => item.value"
                    >
                      <template #option="{ label }">
                        {{ $t(label) }}
                      </template>
                      <template #selected-option="{ label }">
                        {{ $t(label) }}
                      </template>
                    </v-select>
                  </sw-select>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab :title="$t('ContactPerson')">
              <b-row>
                <b-col
                  sm="12"
                  md="6"
                >
                  <sw-select :name="$t('AutomationSection.ContactStatus')">
                    <v-select
                      v-model="contactPersonDefaultContactStatus"
                      :options="contactStatuses"
                    >
                      <template #no-options>
                        {{ $t('NoOptions') }}
                      </template>
                      <template #option="{ name }">
                        {{ name }}
                      </template>
                      <template #selected-option="{ name }">
                        {{ name }}
                      </template>
                    </v-select>
                  </sw-select>
                </b-col>

                <b-col
                  sm="12"
                  md="6"
                >
                  <sw-select :name="$t('ContactPosition')">
                    <v-select
                      v-model="contactPersonDefaultPosition"
                      :options="contactPositionTypes"
                      :reduce="item => item.value"
                    >
                      <template #option="{ label }">
                        {{ $t(label) }}
                      </template>
                      <template #selected-option="{ label }">
                        {{ $t(label) }}
                      </template>
                    </v-select>
                  </sw-select>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>
        </b-col>

        <!-- Uploader -->
        <b-col sm="12">
          <drag-drop-upload
            :disabled="loading"
            :show-files="false"
            @onChangeFiles="changeFiles"
          />
        </b-col>
      </b-row>

      <b-row
        v-if="contactsCount"
        class="mt-1"
      >
        <b-col sm="12">
          <div class="d-flex justify-content-between align-items-center mb-1">
            <div>
              {{ $t('ContactsFromFile') }}
              <b-badge>{{ contactsCount }}</b-badge>
            </div>
          </div>
        </b-col>
        <b-col
          sm="12"
          class="table-padding-x-0"
        >
          <div class="d-flex justify-content-between">
            <div class="d-flex align-items-center">
              <sw-select class="ml-50">
                <v-select
                  v-model="pagination.perPage"
                  :options="[25, 50, 100, 250]"
                  style="max-width: 100px"
                  :clearable="false"
                  class="per-page-selector"
                />
              </sw-select>
              <b-button
                class="ml-50 mb-1"
                variant="primary"
                size="sm"
                @click="synchronizeWithGus"
              >
                {{ $t('SyncWithCSO') }}
              </b-button>
            </div>
            <!-- Pagination -->
            <b-pagination
              v-model="pagination.currentPage"
              :total-rows="pagination.totalRecords"
              :per-page="pagination.perPage"
              align="center"
            />
          </div>
          <div>
            <b-form-input
              v-model="search"
              :placeholder="$t('TypeToSearch')"
            />
          </div>
          <validation-observer
            ref="validator"
            tag="div"
          >
            <b-table
              :items="getFilteredContacts"
              :fields="contactFields"
              responsive
              fixed
              :tbody-tr-class="getTrClasses"
              hover
              show-empty
              :total="pagination.totalRecords"
              :current-page="pagination.currentPage"
              :per-page="pagination.perPage"
            >
              <template #table-colgroup>
                <col style="width: 5rem">
                <col style="width: 12rem">
                <col style="width: 12rem">
                <col style="width: 12rem">
                <col style="width: 12rem">
                <col style="width: 12rem">
                <col style="width: 15rem">
                <col style="width: 15rem">
                <col style="width: 15rem">
                <col style="width: 15rem">
                <col style="width: 12rem">
                <col style="width: 12rem">
                <col style="width: 12rem">
                <col style="width: 12rem">
                <col style="width: 16rem">

                <col style="width: 14rem">
                <col style="width: 14rem">
                <col style="width: 14rem">
                <col style="width: 14rem">
                <col style="width: 14rem">
                <col style="width: 14rem">
                <col style="width: 14rem">
                <col style="width: 14rem">
                <col style="width: 14rem">
                <col style="width: 14rem">

                <!-- add as many <col> elements as needed -->
              </template>

              <template #empty>
                <div class="text-center py-1 text-primary font-weight-bold">
                  <feather-icon
                    size="17"
                    icon="XCircleIcon"
                  />
                  {{ $t('NoData') }}
                </div>
              </template>
              <template #head()="{ label, field }">
                <span v-if="field.prefix">{{ $t(field.prefix) }}: </span>{{ $t(label) }}
              </template>

              <template #cell(action)="{ index }">
                <b-button
                  variant="flat-danger"
                  size="sm"
                  @click="$delete(importedContacts, index)"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </template>

              <template #cell(tags)="{ value, field, index }">
                <validation-provider
                  rules="required"
                >
                  <div style="width: 12rem">
                    <sw-select>
                      <v-select
                        :value="value"
                        :options="contactTags"
                        class="select-size-sm"
                        label="name"
                        multiple
                        :clearable="false"
                        @option:selected="changeContactFieldValue($event, field, index)"
                      >
                        <template #no-options>
                          {{ $t('NoOptions') }}
                        </template>
                        <template #option="{ name }">
                          {{ name }}
                        </template>
                        <template #selected-option="{ name }">
                          {{ name }}
                        </template>
                      </v-select>
                    </sw-select>
                  </div>
                </validation-provider>
              </template>

              <template #cell(contactPersonTags)="{ value, field, index }">
                <validation-provider
                  rules="required"
                >
                  <div style="width: 12rem">
                    <sw-select>
                      <v-select
                        :value="value"
                        :options="contactTags"
                        class="select-size-sm"
                        label="name"
                        multiple
                        :clearable="false"
                        @option:selected="changeContactFieldValue($event, field, index)"
                      >
                        <template #no-options>
                          {{ $t('NoOptions') }}
                        </template>
                        <template #option="{ name }">
                          {{ name }}
                        </template>
                        <template #selected-option="{ name }">
                          {{ name }}
                        </template>
                      </v-select>
                    </sw-select>
                  </div>
                </validation-provider>
              </template>

              <template #cell()="{ value, field, index }">
                <b-form-input
                  :value="value"
                  size="sm"
                  @input="changeContactFieldValue($event, field, index)"
                />
              </template>

              <template #cell(phone)="{ value, field, index, item }">
                <b-input-group
                  class="mr-2"
                >
                  <b-form-input
                    :state="value ? !Boolean(item && item.phoneExist) : null"
                    :value="value"
                    size="sm"
                    @blur="changeContactFieldValue($event.target.value, field, index)"
                  />
                </b-input-group>
              </template>

              <template #cell(email)="{ value, field, index, item }">
                <b-input-group
                  class="mr-2"
                >
                  <b-form-input
                    :state="value ? !Boolean((item && item.emailExist)) : null"
                    :value="value"
                    size="sm"
                    @blur="changeContactFieldValue($event.target.value, field, index)"
                  />
                </b-input-group>
              </template>

              <template #cell(contactPersonPhone)="{ value, field, index, item }">
                <b-input-group
                  class="mr-2"
                >
                  <b-form-input
                    :state="value ? !Boolean(item && item.contactPersonPhoneExist) : null"
                    :value="value"
                    size="sm"
                    @blur="changeContactFieldValue($event.target.value, field, index)"
                  />
                </b-input-group>
              </template>

              <template #cell(contactPersonEmail)="{ value, field, index, item }">
                <b-input-group
                  class="mr-2"
                >
                  <b-form-input
                    :state="value ? !Boolean((item && item.contactPersonEmailExist)) : null"
                    :value="value"
                    size="sm"
                    @blur="changeContactFieldValue($event.target.value, field, index)"
                  />
                </b-input-group>
              </template>

              <template #cell(assignedUsers)="{ value, field, index }">
                <div style="width: 200px">
                  <assigned-users
                    v-if="!value.some(e => typeof e === 'string')"
                    :value="value"
                    :is-multiple="true"
                    :classes="['select-size-sm']"
                    @input="changeContactFieldValue($event, field, index)"
                  />
                </div>
              </template>

              <template #cell(position)="{ field, index, value }">
                <sw-select>
                  <v-select
                    class="select-size-sm"
                    :value="value"
                    :options="contactPositionTypes"
                    :reduce="item => item.value"
                    @option:selected="changeContactFieldValue($event, field, index)"
                  >
                    <template #option="{ label }">
                      {{ $t(label) }}
                    </template>
                    <template #selected-option="{ label }">
                      {{ $t(label) }}
                    </template>
                  </v-select>
                </sw-select>
              </template>
              <template #cell(funnel)="{ field, index, value }">
                <sw-select>
                  <v-select
                    label="name"
                    class="select-size-sm"
                    :value="value"
                    :options="contactThreadFunnelTypesPoints"
                    @option:selected="changeContactFieldValue($event, field, index)"
                  />
                </sw-select>
              </template>
              <template #cell(contactStatus)="{ value, field, index }">
                <validation-provider
                  rules="required"
                >
                  <div style="width: 200px">
                    <sw-select>
                      <v-select
                        :value="value"
                        :options="contactStatuses"
                        class="select-size-sm"
                        label="name"
                        :clearable="false"
                        @option:selected="changeContactFieldValue($event, field, index)"
                      >
                        <template #no-options>
                          {{ $t('NoOptions') }}
                        </template>
                        <template #option="{ name, color }">
                          <div
                            class="color-dot"
                            :style="{ backgroundColor: color }"
                          />
                          {{ name }}
                        </template>
                        <template #selected-option="{ name, color }">
                          <div
                            class="color-dot"
                            :style="{ backgroundColor: color }"
                          />
                          {{ name }}
                        </template>
                      </v-select>
                    </sw-select>
                  </div>
                </validation-provider>
              </template>
            </b-table>
          </validation-observer>

          <div class="d-flex justify-content-between">
            <sw-select class="ml-50">
              <v-select
                v-model="pagination.perPage"
                :options="[25, 50, 100, 250]"
                style="max-width: 100px"
                :clearable="false"
                class="per-page-selector w-100"
              />
            </sw-select>
            <!-- Pagination -->
            <b-pagination
              v-model="pagination.currentPage"
              :total-rows="pagination.totalRecords"
              :per-page="pagination.perPage"
              align="center"
            />
          </div>
        </b-col>
        <b-col
          v-if="existedEmails.length"
          sm="12"
        >
          <b-alert
            show
            variant="danger"
          >
            <h4 class="alert-heading">
              {{ $t('TypedEmailAddressesAlreadyExistsInDatabase') }}
            </h4>
            <div class="alert-body">
              <b-row>
                <b-col
                  v-for="item in existedEmails.filter(Boolean)"
                  :key="`existedEmails_${item}`"
                  sm="6"
                  md="4"
                >
                  <feather-icon icon="CircleIcon" />
                  {{ item }}
                </b-col>
              </b-row>
            </div>
          </b-alert>
        </b-col>
        <b-col
          v-if="invalidEmails.length"
          sm="12"
        >
          <b-alert
            show
            variant="danger"
          >
            <h4 class="alert-heading">
              {{ $t('TypedEmailAddressesAreInvalid') }}
            </h4>
            <div class="alert-body">
              <b-row>
                <b-col
                  v-for="item in invalidEmails.filter(Boolean)"
                  :key="`invalidEmails_${item}`"
                  sm="6"
                  md="4"
                >
                  <feather-icon icon="CircleIcon" />
                  {{ item }}
                </b-col>
              </b-row>
            </div>
          </b-alert>
        </b-col>

        <b-col
          v-if="existedPhones.length"
          sm="12"
        >
          <b-alert
            show
            variant="danger"
          >
            <h4 class="alert-heading">
              {{ $t('TypedPhonesAlreadyExistsInDatabase') }}
            </h4>
            <div class="alert-body">
              <b-row>
                <b-col
                  v-for="item in existedPhones.filter(Boolean)"
                  :key="`existedPhones_${item}`"
                  sm="6"
                  md="4"
                >
                  <feather-icon icon="CircleIcon" />
                  {{ item }}
                </b-col>
              </b-row>
            </div>
          </b-alert>
        </b-col>
        <b-col
          v-if="invalidPhones.length"
          sm="12"
        >
          <b-alert
            show
            variant="danger"
          >
            <h4 class="alert-heading">
              {{ $t('TypedPhoneAreInvalid') }}
            </h4>
            <div class="alert-body">
              <b-row>
                <b-col
                  v-for="item in invalidPhones.filter(Boolean)"
                  :key="`invalidPhones_${item}`"
                  sm="6"
                  md="4"
                >
                  <feather-icon icon="CircleIcon" />
                  {{ item }}
                </b-col>
              </b-row>
            </div>
          </b-alert>
        </b-col>
        <b-col
          v-if="duplicates && duplicates.length"
          sm="12"
        >
          <b-alert
            show
            variant="danger"
          >
            <h4 class="alert-heading">
              {{ duplicatesMessage }}
            </h4>
            <div class="alert-body">
              <b-row>
                <b-col
                  v-for="item in duplicates.filter(Boolean)"
                  :key="`duplicates_${item}`"
                  sm="6"
                  md="4"
                >
                  <feather-icon icon="CircleIcon" />
                  <span v-if="item && item.name">{{ item.name }}</span>
                  <span v-else>{{ item }}</span>
                </b-col>
              </b-row>
            </div>
          </b-alert>
        </b-col>
        <b-col sm="12">
          <b-button
            :ok-disabled="!importedContacts.length"
            size="sm"
            class="mr-25"
            variant="primary"
            :disabled="
              Boolean(existedEmails.length) ||
                Boolean(invalidEmails.length) ||
                Boolean(existedPhones.length) ||
                Boolean(invalidPhones.length)
            "
            @click="saveImportedContacts"
          >
            {{ $t('Importuj') }}
          </b-button>
          <b-button
            size="sm"
            variant="outline-secondary"
            @click="closeModal"
          >
            {{ $t('Cancel') }}
          </b-button>
        </b-col>
      </b-row>
      <b-overlay
        no-wrap
        :show="loading"
        spinner-variant="primary"
      />
    </b-modal>
  </div>
</template>

<script>
import { BAlert, BModal, BTable } from 'bootstrap-vue'
import draggable from 'vuedraggable'

import XLSX from 'xlsx'
import { GET_CONTACT_STATUSES, GET_THREAD_TAGS } from '@/@constants/mutations'
import AssignedUsers from '@/views/components/AssignedUsers.vue'
import vSelect from 'vue-select'
import axiosIns from '@/libs/axios'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import { mapGetters } from 'vuex'
import { contactPositionTypes } from '@/@types/contactPositionTypes'
import DragDropUpload from '../components/DragDropUpload.vue'

const fields = [
  { label: 'CompanyName', key: 'name' },
  { label: 'Nip', key: 'vatId' },
  { label: 'contact.addressCountry', key: 'addressCountry' },
  { label: 'contact.addressCity', key: 'addressCity' },
  { label: 'contact.addressPostalCode', key: 'addressPostalCode' },
  { label: 'contact.addressStreet', key: 'addressStreet' },
  { label: 'contact.addressNumber', key: 'addressNumber' },
  { label: 'Email', key: 'email' },
  { label: 'Phone', key: 'phone' },
  { label: 'Name', key: 'processName' },
  { label: 'Tags', key: 'tags' },
  { label: 'Note', key: 'note' },
  { prefix: 'ContactPerson', label: 'FirstName', key: 'firstName' },
  { prefix: 'ContactPerson', label: 'LastName', key: 'lastName' },
  { prefix: 'ContactPerson', label: 'Email', key: 'contactPersonEmail' },
  { prefix: 'ContactPerson', label: 'Phone', key: 'contactPersonPhone' },
  { prefix: 'ContactPerson', label: 'Tags', key: 'contactPersonTags' },
  { label: 'AssignedContactUsers', key: 'assignedUsers' },
]

export default {
  name: 'ImportCompaniesModal',
  components: {
    BAlert,
    BTable,
    BModal,
    vSelect,
    draggable,
    DragDropUpload,
    AssignedUsers,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    show: {
      default: true,
      type: Boolean,
    },
  },
  data: () => ({
    required,
    fields: [...fields],
    fieldsOrder: [...fields],
    disableMainContact: false,
    disableAdditionalContact: false,
    assignToAdditionalContact: false,

    defaultFunnel: '',
    defaultPosition: '',
    defaultContactStatus: '',

    contactPersonDefaultFunnel: '',
    contactPersonDefaultPosition: '',
    contactPersonDefaultContactStatus: '',

    importedContacts: [],
    preview: false,
    contactsCount: 0,
    contactStatuses: [],
    contactPositionTypes,
    contactThreadFunnelTypesPoints: [],

    duplicates: [],
    duplicatesMessage: '',

    loading: false,
    sorter: {
      sortBy: 'createdAt',
      sortDesc: true,
    },
    pagination: {
      perPage: 100,
      currentPage: 1,
      totalRecords: 100,
    },
    search: '',
    contactTags: [],
  }),
  computed: {
    contactFields() {
      return [
        { key: 'action', label: 'Action' },
        ...this.fields.slice(0, 7),
        { key: 'funnel', label: 'Funnel' },
        { key: 'contactStatus', label: 'AutomationSection.ContactStatus' },
        { key: 'position', label: 'ContactPosition' },
        ...this.fields.slice(7),
        { prefix: 'AddressForCorrespondence', label: 'OrganizationDetails.addressCountry', key: 'addressCorrespondenceCountry' },
        { prefix: 'AddressForCorrespondence', label: 'OrganizationDetails.addressCity', key: 'addressCorrespondenceCity' },
        { prefix: 'AddressForCorrespondence', label: 'OrganizationDetails.addressPostalCode', key: 'addressCorrespondencePostalCode' },
        { prefix: 'AddressForCorrespondence', label: 'OrganizationDetails.addressStreet', key: 'addressCorrespondenceStreet' },
        { prefix: 'AddressForCorrespondence', label: 'OrganizationDetails.addressNumber', key: 'addressCorrespondenceNumber' },
        { label: 'OrganizationDetails.addressCountry', key: 'addressCountry' },
        { label: 'OrganizationDetails.addressCity', key: 'addressCity' },
        { label: 'OrganizationDetails.addressPostalCode', key: 'addressPostalCode' },
        { label: 'OrganizationDetails.addressStreet', key: 'addressStreet' },
        { label: 'OrganizationDetails.addressNumber', key: 'addressNumber' },
      ]
    },
    duplicatedNips() {
      return this.importedContacts.map(({ vatId }) => vatId).getDuplicates()
    },
    invalidEmails() {
      return [...new Set(
        (this.importedContacts.map(contact => contact.email).filter(email => !this.isValidEmail(email)) || []),
        (this.importedContacts.map(contact => contact.contactPersonEmail).filter(email => !this.isValidEmail(email)) || []),
      )].filter(Boolean)
    },
    existedEmails() {
      return [...new Set(
        (this.importedContacts.filter(contact => contact.emailExist).map(contact => contact.email) || []),
        (this.importedContacts.filter(contact => contact.contactPersonEmailExist).map(contact => contact.contactPersonEmail) || []),
      )].filter(Boolean)
    },
    existedPhones() {
      return [...new Set(
        (this.importedContacts.filter(contact => contact.phoneExist).map(contact => contact.phone) || []),
        (this.importedContacts.filter(contact => contact.contactPersonPhoneExist).map(contact => contact.contactPersonPhone) || []),
      )].filter(Boolean)
    },
    invalidPhones() {
      return [...new Set(
        (this.importedContacts.map(contact => contact.phone).filter(phone => !this.isValidPhone(phone)) || []),
        (this.importedContacts.map(contact => contact.contactPersonPhone).filter(phone => !this.isValidPhone(phone)) || []),
      )].filter(Boolean)
    },
    getFilteredContacts() {
      if (this.search) {
        return this.importedContacts.filter(e => e.phone?.includes(this.search)
                || e.contactPersonPhone?.includes(this.search)
                || e.email?.includes(this.search)
                || e.contactPersonEmail?.includes(this.search))
      }

      return this.importedContacts
    },

    ...mapGetters({
      user: 'auth/getCurrentUser',
    }),
  },
  watch: {
    importedContacts: {
      deep: true,
      handler(newValue, oldValue) {
        if (!newValue.length && oldValue.length) {
          this.importedContacts = oldValue
        }
      },
    },
  },
  async mounted() {
    await Promise.all([
      this.loadContactStatuses(),
      this.loadThreadStatuses(),
      this.loadContactTags(),
    ])
  },
  methods: {
    closeModal() {
      this.importedContacts = []
      this.preview = false
      this.contactsCount = 0
      this.$emit('close-modal')
    },
    pushItem(field) {
      if (!this.isUsed(field)) this.fieldsOrder.push(field)
    },
    isUsed(field) {
      return this.fieldsOrder.includes(field)
    },
    changeFiles(files) {
      if (files.length) this.readFile(files[0])
    },
    async synchronizeWithGus() {
      this.importedContacts.forEach(async (contact, index) => {
        await this.downloadFromGus(contact.vatId, index)
      })
    },
    async downloadFromGus(nip, index) {
      if (!nip) return false

      this.importedContacts[index].loading = true

      try {
        const resp = await axiosIns.get(`1/integration/gus/byNip/${String(nip).replace(/[^a-zA-Z0-9 ]/g, '').replaceAll(' ', '')}`)

        if (resp?.data?.data.length) {
          const company = resp.data.data[0]

          this.$set(this.importedContacts[index], 'name', company.name)
          this.$set(this.importedContacts[index], 'addressCity', company.city)
          this.$set(this.importedContacts[index], 'addressCountry', 'Polska')
          this.$set(this.importedContacts[index], 'addressNumber', company.propertyNumber)
          this.$set(this.importedContacts[index], 'addressPostalCode', company.zipCode)
          this.$set(this.importedContacts[index], 'addressStreet', company.street)

          this.$set(this.importedContacts[index], 'addressCorrespondenceCity', company.city)
          this.$set(this.importedContacts[index], 'addressCorrespondenceCountry', 'Polska')
          this.$set(this.importedContacts[index], 'addressCorrespondenceNumber', company.propertyNumber)
          this.$set(this.importedContacts[index], 'addressCorrespondencePostalCode', company.zipCode)
          this.$set(this.importedContacts[index], 'addressCorrespondenceStreet', company.street)
        }
      } catch (err) {
        return err
      } finally {
        this.importedContacts[index].loading = false
      }

      return this.importedContacts[index]
    },
    resolveTags(tagsString = '') {
      const tagsList = tagsString.split(',').map(tag => String(tag).trim()).filter(Boolean)
      if (tagsList.length) {
        return tagsList.map(tagItem => {
          const tag = this.contactTags.find(t => t.name.toLowerCase() === tagItem.toLowerCase().trim())
          if (!tag) return null

          return tag
        }).filter(Boolean)
      }

      if (!tagsString && this.defaultTags?.length) return [this.defaultTags]
      if (!tagsString && !this.defaultTags?.length) return []

      return [this.contactTags[0]]
    },
    async loadContactTags() {
      this.loading = true
      try {
        const { data } = await this.$store.dispatch(`typeSettings/${GET_THREAD_TAGS}`)
        this.contactTags = data.items
        return data.items
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        return err
      } finally {
        this.loading = false
      }
    },
    readFile(file) {
      const reader = new FileReader()
      reader.onload = e => {
        const ab = e.target.result
        const wb = XLSX.read(new Uint8Array(ab), { type: 'array' })
        const wsname = wb.SheetNames[0]
        const ws = wb.Sheets[wsname]
        const data = XLSX.utils.sheet_to_json(ws, { blankrows: false, header: 1 })

        this.prepareContactsFromXLS(data)
      }
      reader.readAsArrayBuffer(file)
    },
    async prepareContactsFromXLS(rows = []) {
      if (rows[0].includes(this.$i18n.t('CompanyName')) || rows[0].includes(this.$i18n.t('NIP'))) rows.shift()
      const contacts = []

      try {
        rows.forEach(contactRow => {
          if (Array.isArray(contactRow) && !contactRow.length) return

          const contact = {
            assignedUsers: [
              { ...this.$store.getters['auth/getCurrentUser'] },
            ],
            funnel: this.defaultFunnel || this.contactThreadFunnelTypesPoints[0],
            contactStatus: this.defaultContactStatus || this.contactStatuses[0],
            position: this.defaultPosition || null,
          }
          fields.forEach(contactField => {
            const index = this.fieldsOrder.indexOf(contactField)

            contact.addressCorrespondenceCountry = ''
            contact.addressCorrespondenceCity = ''
            contact.addressCorrespondencePostalCode = ''
            contact.addressCorrespondenceStreet = ''
            contact.addressCorrespondenceNumber = ''
            // contact.addressCountry = ''
            // contact.addressCity = ''
            // contact.addressPostalCode = ''
            // contact.addressStreet = ''
            // contact.addressNumber = ''

            if (contactField.key === 'phone' || contactField.key === 'contactPersonPhone') {
              contact[contactField.key] = String(contactRow[index]) === 'undefined' ? '' : String(contactRow[index]).replaceAll(' ', '')
            } else if (contactField.key === 'tags' || contactField.key === 'contactPersonTags') {
              contact[contactField.key] = this.resolveTags(contactRow[index])
            } else if (contactField.key === 'email' || contactField.key === 'contactPersonEmail') {
              contact[contactField.key] = contactRow[index] || ''
              // eslint-disable-next-line prefer-destructuring
              contact[contactField.key] = contact[contactField.key].split('/')[0].trim()
            } else if (contactField.key !== 'assignedUsers') {
              contact[contactField.key] = contactRow[index] || ''
            } else {
              contact[contactField.key] = contactRow[index] ? String(contactRow[index]).replaceAll(' ', '').split(',') : []
            }
          })
          contact.vatId = contact?.vatId ? String(contact?.vatId).replaceAll(' ', '') : ''
          contacts.push(contact)
        })
      } catch (err) {
        console.error(err)
      }
      // this.$set(this, 'importedContacts', contacts)
      Promise.all([this.checkExistedEmailsNew(contacts), this.checkExistedPhones(contacts)])
        .then(async () => {
          if (contacts.length) {
            this.contactsCount = contacts.length
            this.pagination.totalRecords = contacts.length

            if (this.fieldsOrder.find(e => e.key === 'assignedUsers')) {
              await this.resolveAssignedUsers(contacts)
                .then(res => {
                  this.$set(this, 'importedContacts', res)
                })
            } else this.$set(this, 'importedContacts', contacts)
          }
        })
        .catch(err => {
          console.error(err)
        })
    },
    // New
    // eslint-disable-next-line no-unused-vars
    checkExistedEmailsNew(contacts = [], value = null) {
      const allEmails = [...contacts.map(user => user?.email), ...contacts.map(user => user?.contactPersonEmail)].filter(Boolean)
      const duplicateElementa = allEmails.filter((e, i, a) => a.indexOf(e) !== i)

      return new Promise((resolve, reject) => {
        const emails = Array.isArray(contacts)
          ? [...contacts.map(contact => contact.email), ...contacts.map(contact => contact.contactPersonEmail)]
          : [typeof contacts === 'object' ? contacts.email : contacts]

        axiosIns.post('1/contacts/checkMailAddresses', value ? [value, ...emails?.filter(Boolean)] : emails?.filter(Boolean))
          .then(res => {
            const duplicates = [...res.data.data.exists, ...duplicateElementa].filter(Boolean)

            this.changeExistedEmails(duplicates, contacts)
            resolve()
          })
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
            reject(err)
          })
      })
    },
    isValidEmail(email) {
      const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      return validRegex.test(email)
    },
    isValidPhone(phone) {
      // eslint-disable-next-line no-underscore-dangle
      const _phone = phone ? String(phone) : null
      if (!_phone) return true

      // Test no country code
      let validRegex = /^\+\d{1,3}\s\d{1,14}$/
      if (_phone && _phone.includes('+') && validRegex) return validRegex.test(_phone)

      validRegex = /^\d{1,14}$/
      if (_phone) return validRegex.test(_phone)

      return true
    },
    changeExistedEmails(duplicates, contacts) {
      contacts.forEach(contact => {
        if (contact.email && (duplicates.includes(contact.email) || !this.isValidEmail(contact.email))) {
          this.$set(contact, 'emailExist', true)
        } else {
          this.$set(contact, 'emailExist', false)
        }

        if (contact.contactPersonEmail && (duplicates.includes(contact.contactPersonEmail) || !this.isValidEmail(contact.contactPersonEmail))) {
          this.$set(contact, 'contactPersonEmailExist', true)
        } else {
          this.$set(contact, 'contactPersonEmailExist', false)
        }
      })
    },
    /* eslint-disabled */
    // Existed phones
    // eslint-disable-next-line no-unused-vars
    checkExistedPhones(contacts = [], value = null) {
      const allPhones = [...contacts.map(user => user?.phone), ...contacts.map(user => user?.contactPersonPhone)].filter(Boolean).map(String)
      const duplicateElementa = allPhones.filter((e, i, a) => a.indexOf(e) !== i)

      return new Promise((resolve, reject) => {
        let phones = Array.isArray(contacts) ? [...contacts.map(contact => String(contact.phone)), ...contacts.map(contact => String(contact.contactPersonPhone))] : [typeof contacts === 'object' ? String(contacts.phone) : contacts]
        phones = phones.map(phone => {
          if (!phone) return ''
          if (phone.includes('+')) return phone

          return `+48 ${phone.trim()}`
        })

        axiosIns.post('1/contacts/getExistsByPhones', value ? [value, ...phones].filter(Boolean) : phones.filter(Boolean))
          .then(res => {
            const duplicates = [...res.data.data.exists.map(c => c.phone), ...duplicateElementa].filter(Boolean)
            this.changeExistedPhones(duplicates, contacts)
            resolve()
          })
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
            reject(err)
          })
      })
    },
    changeExistedPhones(duplicates, contacts) {
      contacts.forEach(contact => {
        if (contact.phone && (duplicates.includes(contact.phone) || duplicates.includes(`+48 ${contact.phone.trim()}`) || !this.isValidPhone(contact.phone))) {
          this.$set(contact, 'phoneExist', true)
        } else {
          this.$set(contact, 'phoneExist', false)
        }

        if (contact.contactPersonPhone && (duplicates.includes(contact.contactPersonPhone) || duplicates.includes(`+48 ${contact.contactPersonPhone.trim()}`) || !this.isValidPhone(contact.contactPersonPhone))) {
          this.$set(contact, 'contactPersonPhoneExist', true)
        } else {
          this.$set(contact, 'contactPersonPhoneExist', false)
        }
      })
    },

    resolveAssignedUsers(contacts) {
      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve, reject) => {
        let emails = []
        contacts.forEach(contact => {
          emails.push(...contact.assignedUsers)
        })
        emails = [...new Set(emails)]

        const params = {
          fields_load: this.$fields.USERS_SEARCH,
          'eqArr_mailAddress-mail': JSON.stringify(emails),
        }
        axiosIns.get('1/users', { params })
          .then(res => {
            // eslint-disable-next-line no-unused-vars
            const users = res.data.data.items
            contacts.forEach(contact => {
              const usersToAssign = []
              contact.assignedUsers.forEach(user => {
                const userToAssign = users.find(dbUser => dbUser.email === user)
                if (userToAssign) usersToAssign.push(userToAssign)
              })
              if (!usersToAssign.length) {
                if (!usersToAssign.some(user => user?.id === this.$store.getters['auth/getCurrentUser'].id)) usersToAssign.push({ ...this.$store.getters['auth/getCurrentUser'] })
              }
              // eslint-disable-next-line
              contact.assignedUsers = usersToAssign
            })
            resolve(contacts)
          })
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
            reject()
          })
      })
    },
    emailExist(item) {
      return !item?.emailExist ? { icon: 'CheckIcon', classes: 'text-success' } : { icon: 'AlertCircleIcon', classes: 'text-danger' }
    },
    phoneExist(item) {
      return !item?.phoneExist ? { icon: 'CheckIcon', classes: 'text-success' } : { icon: 'AlertCircleIcon', classes: 'text-danger' }
    },
    async loadThreadStatuses() {
      try {
        const resp = await axiosIns.get('1/settings/contactThreadFunnelTypes', { params: { fields_load: this.$fields.SETTINGS_FUNNEL_TYPE, orderBy: 'position.asc' } })

        if (resp?.data?.data?.items) {
          this.contactThreadFunnelTypesPoints = (resp.data.data.items || []).flatMap(type => type.contactThreadFunnelTypePoints.map(point => ({ ...point, typeId: type.id, name: `${type.name} - ${point.name}` })))
        }
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    async loadContactStatuses() {
      this.loading = true

      try {
        const { data } = await this.$store.dispatch(`typeSettings/${GET_CONTACT_STATUSES}`)
        this.contactStatuses = data.items
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.loading = false
      }
    },
    /* eslint-disable */
        changeContactFieldValue(value, { key }, index) {
            this.$set(this.importedContacts[index], key, value)

            if (key === 'email' || key === 'contactPersonEmail') {
                this.$nextTick(() => {
                    this.checkExistedEmailsNew(this.importedContacts, value)
                })
            }

            if (key === 'phone' || key === 'contactPersonPhone') {
                this.$nextTick(() => {
                    this.checkExistedPhones(this.importedContacts, value)
                })
            }
        },
        getTrClasses(item, type) {
            if (item?.emailExist || item?.phoneExist) return ['border border-danger border-3']
            return []
        },
        /* eslint-disable */
        async saveImportedContacts() {
            // const isValid = await this.$refs.validator.validate()
            // if (!isValid) return

            let contacts = JSON.parse(JSON.stringify(this.importedContacts))
            let companies = contacts.map(contact => {
                const r = {
                    "name": String(contact.name),
                    "vatId": contact.vatId,
                    "addressCountry": String(contact.addressCountry || ''),
                    "addressCity": String(contact.addressCity || ''),
                    "addressPostalCode": String(contact.addressPostalCode || ''),
                    "addressStreet": String(contact.addressStreet || ''),
                    "addressNumber": String(contact.addressNumber || ''),
                    "addressCorrespondenceCountry": String(contact.addressCorrespondenceCountry || ''),
                    "addressCorrespondenceCity": String(contact.addressCorrespondenceCity || ''),
                    "addressCorrespondencePostalCode": String(contact.addressCorrespondencePostalCode || ''),
                    "addressCorrespondenceStreet": String(contact.addressCorrespondenceStreet || ''),
                    "addressCorrespondenceNumber": String(contact.addressCorrespondenceNumber || ''),
                    "vatPayer": false,
                    "vatIdType": "eu_vat",
                    thread: {
                        name: String(contact?.processName || contact?.funnel?.name),
                        predictedValue: 0,
                        predictedValueCurrency: 'PLN',
                        assignedLeadUser: contact.assignedUsers.length ? this.getObjectId(contact.assignedUsers[0]) : null,
                        assignedUsers: (contact.assignedUsers || []).map(user => (typeof user === 'object' ? user.id : user)),
                        contactThreadFunnelTypePoint: contact?.funnel?.id
                    },
                    mainContact: {
                      "mainOrganizationContact": true,
                      "isOrganization": true,
                      "firstName": this.$i18n.t('Organization'),
                      "lastName": String(contact.name),
                      "status": this.getObjectId(contact.contactStatus),
                      "position": contact.position?.value || contact?.position || null,
                      contactTypes: [],
                    },
                    additionalContact: {
                        "note": contact.note,
                        "firstName": String(contact.firstName) || this.$i18n.t('ContactPerson'),
                        "lastName": String(contact.lastName) || String(contact.name),
                        "isOrganization": true,
                        "status": this.getObjectId(this.contactPersonDefaultContactStatus) || this.getObjectId(contact.contactStatus),
                        "position": this.contactPersonDefaultPosition || contact.position?.value || contact?.position || null,
                        contactTypes: [],
                    },
                }

                if (contact.tags?.length) {
                    r.mainContact.contactTypes = (contact.tags || []).mapKeys('id')
                }

                if (contact.contactPersonTags?.length) {
                    r.additionalContact.contactTypes = (contact.contactPersonTags || []).mapKeys('id')
                }

                r.mainContact.phone = contact.phone.toString().trim()
                if (contact.phone && !contact.phone.includes('+')) r.mainContact.phone = `+48 ${contact.phone.trim().replaceAll(' ', '')}`
                r.mainContact.phoneNumber = r.mainContact.phone.toString()
                r.mainContact.phoneNumbers = [r.mainContact.phone.toString()]

                r.additionalContact.phone = contact.contactPersonPhone.toString().trim()
                if (contact.contactPersonPhone && !contact.contactPersonPhone.includes('+')) r.additionalContact.phone = `+48 ${contact.contactPersonPhone.trim().replaceAll(' ', '')}`
                r.additionalContact.phoneNumber = r.additionalContact.phone.toString()
                r.additionalContact.phoneNumbers = [r.additionalContact.phone.toString()]

                // r.mainContact.status = typeof contact.status === 'object' ? contact.status.id : contact.status
                // r.additionalContact.status = typeof contact.status === 'object' ? contact.status.id : contact.status

                r.mainContact.assignedUsers = contact.assignedUsers.map(user => (typeof user === 'object' ? user.id : user))
                r.additionalContact.assignedUsers = contact.assignedUsers.map(user => (typeof user === 'object' ? user.id : user))

                r.mainContact.mailAddress = contact.email?.trim()?.replaceAll(' ', '')?.replaceAll('\n', '')
                r.mainContact.mailAddresses = [contact.email?.trim()?.replaceAll(' ', '')?.replaceAll('\n', '')]

                r.additionalContact.mailAddress = contact.contactPersonEmail?.trim()?.replaceAll(' ', '')?.replaceAll('\n', '')
                r.additionalContact.mailAddresses = [contact.contactPersonEmail?.trim()?.replaceAll(' ', '')?.replaceAll('\n', '')]

                r.additionalContact.isOrganization = false
                r.mainContact.isOrganization = true

                return r
            })
            // Nazwa firmy (szkoły rodzenia) ,	Miasto organizacji  ,	Telefon organizacji ,	Nazwa procesu   ,	Użytkownik przypisany (e-mail)
            const [isOk, duplicates, message] = await this.verifyDuplicates(companies)
            if (!isOk) {
                this.duplicates = duplicates
                this.duplicatesMessage = message

                return
            }

            const oranizationsChunks = companies.splitIntoChunks(25)
            // Create Organizations
            try {
                const response = (await (Promise.all(oranizationsChunks.map(organizationChunk => this.uploadOrganizations(organizationChunk))))).flatMap(arr => arr)

                // [{ id: '', mainContact, additionalContact }]
                const mainContactsChunks = this.disableMainContact ? [] : response.map(organization => ({ ...organization.mainContact, organization: organization.id, firstName: this.$i18n.t('') })).splitIntoChunks(25)
                const additionalContactsChunks = this.disableAdditionalContact ? [] : response.map(organization => ({ ...organization.additionalContact, organization: organization.id })).splitIntoChunks(25)

                const mainContactsChunksResponse = (await (Promise.all(mainContactsChunks.map(contactChunk => this.uploadContacts(contactChunk))))).flatMap(arr => arr)
                const additionalContactsChunksResponse = (await (Promise.all(additionalContactsChunks.map(contactChunk => this.uploadContacts(contactChunk))))).flatMap(arr => arr)

                const threadChunks = response.map((organization, index) => ({
                    mainContact: this.disableMainContact ? [] : mainContactsChunksResponse[index],
                    additionalContact: this.disableAdditionalContact ? [] : additionalContactsChunksResponse[index],
                    organization: this.getObjectId(organization),
                    ...organization.thread
                })).splitIntoChunks(25)

                const threadChunksResponse = (await (Promise.all(threadChunks.map(contactChunk => this.uploadThreads(contactChunk))))).flatMap(arr => arr)

                const threadProcessesChunks = response.map((organization, index) => ({
                    contactThreadFunnelTypePoint: organization.thread.contactThreadFunnelTypePoint,
                    id: this.getObjectId(threadChunksResponse[index])
                })).splitIntoChunks(25)
                const threadProcessesResponse = (await (Promise.all(threadProcessesChunks.map(contactChunk => this.uploadProcesses(contactChunk))))).flatMap(arr => arr)

                const threads = []
                this.closeModal()
            } catch (err) {
                console.log(err)
            }

            // {
            //     "mainOrganizationContact": true,
            //     "isOrganization": true,
            //     "organization": "19",
            //     "mailAddresses": [
            //     "test123@w2p.pl"
            // ],
            //     "mailAddress": "test123@w2p.pl",
            //     "phoneNumbers": [
            //     "+48 123000432"
            // ],
            //     "phoneNumber": "+48 123000432",
            //     "status": "1001",
            //     "firstName": "Firma",
            //     "lastName": "ŁUKASZ KONINA \"KEOS MEDIA\""
            // }


            // {
            //     "name": "Obsługa",
            //     "predictedValue": 2000,
            //     "predictedValueCurrency": "PLN",
            //     "contactThreadFunnelTypePoint": "1032",
            //     "assignedUsers": [
            //     "1"
            // ],
            //     "contactThreadAssignedContacts": [],
            //     "contactThreadChecklistTypes": [
            //     "1001"
            // ]
            // }


            // {
            //     "id": "1038",
            //     "organization": "19"
            // }

            // contacts = contacts.filter(contact => !this.fieldsOrder.includes('email') || !contact.emailExist || !contact.mailAddress).map(contact => contact)
            // contacts = contacts.filter(contact => !this.fieldsOrder.includes('phone') || !contact.phoneExist || !contact.phoneNumber).map(contact => contact)

            // if (!contacts.length) return

            // await this.$store.dispatch('importer/UPLOAD_IMPORTED_CONTACTS', { data: contacts, uploadUrl: '1/contacts', importerLabel: 'Contacts' })
            // this.closeModal()
        },
      async verifyDuplicates(companies) {
            let mainEmails = []
            let additionalEmails = []
            let mainPhones = []
            let additionalPhones = []

          companies.forEach(({ mainContact, additionalContact }) => {
              mainEmails.push(mainContact.mailAddress)
              additionalEmails.push(additionalContact.mailAddress)

              mainPhones.push(mainContact.phoneNumber)
              additionalPhones.push(additionalContact.phoneNumber)
          })

          mainEmails = mainEmails.filter(Boolean)
          additionalEmails = additionalEmails.filter(Boolean)
          mainPhones = mainPhones.filter(Boolean)
          additionalPhones = additionalPhones.filter(Boolean)

          const emailsForSet = [...mainEmails, ...additionalEmails]
          const phonesForSet = [...mainPhones, ...additionalPhones]

          const emailDuplicates = emailsForSet.filter((value, index) => emailsForSet.indexOf(value) !== index);
          const phoneDuplicates = phonesForSet.filter((value, index) => phonesForSet.indexOf(value) !== index);

          if (emailDuplicates.length) {
              return [null, emailDuplicates, 'Znaleziono duplikaty adresów email, usuń je zanim kontynuujesz']
          }
          if (phoneDuplicates.length) {
              return [null, phoneDuplicates, 'Znaleziono duplikaty numerów telefonów, usuń je zanim kontynuujesz']
          }

          const emails = new Set(emailsForSet)
          const phones = new Set(phonesForSet)

          const [emailResponse, phoneResponse, organizaitonResponse] = await Promise.all([
              axiosIns.post('1/contacts/checkMailAddresses', emails),
              axiosIns.post('1/contacts/getExistsByPhones', phones),
              axiosIns.get('1/organizations', { params: { fields_load: 'BASE_NAME', eqArr_vatId: JSON.stringify(companies.mapKeys('vatId')) } })
          ])

          if (emailResponse?.data?.data?.exists?.length) {
              return [null, emailResponse?.data?.data?.exists, 'Wykryto duplikaty adresów email z instniejącymi rekordami w bazie danych, usuń je zanim kontynuujesz']
          }

          if (phoneResponse?.data?.data?.exists?.length) {
              return [null, phoneResponse?.data?.data?.exists, 'Wykryto duplikaty numerów telefonów z instniejącymi rekordami w bazie danych, usuń je zanim kontynuujesz']
          }

          if (organizaitonResponse?.data?.data?.items?.length) {
              return [null, organizaitonResponse?.data?.data?.items, 'Wykryto duplikaty numerów nip z instniejącymi rekordami w bazie danych, usuń je zanim kontynuujesz']
          }

          this.duplicates = []
          this.duplicatesMessage = ''

          return [true, null, null]
      },
      async uploadOrganizations(organizations) {
            const payload = organizations.map(organization => {
                const { mainContact, additionalContact, thread, ...organizationPayload } = organization

                return organizationPayload
            })

          const response = await axiosIns.post('1/organizations', payload)

          if (response.data?.data?.items?.length) {
              return organizations.map((organization, index) => ({ ...organization, id: response.data.data.items[index].id }))
          } else return []
      },
      async uploadContacts(contacts) {
          const response = await axiosIns.post('1/contacts', contacts.map(c => ({ ...c, organization: this.getObjectId(c.organization) })))

          if (response.data?.data?.items?.length) {
              return contacts.map((organization, index) => ({ ...organization, id: response.data.data.items[index].id }))
          } else return []
      },
      async uploadProcesses(processes) {
            const payload = processes.map(process => ({
                contactThread: process.id,
                contactThreadFunnelTypePoint: typeof process.contactThreadFunnelTypePoint === 'object' ? process.contactThreadFunnelTypePoint.id.toString() : process.contactThreadFunnelTypePoint.toString(),
                status: 'OPEN',
            }))
          const response = await axiosIns.post(`1/contacts/threads/funnelProcesses`, payload)

          if (response.data?.data?.items?.length) {
              //
              return processes.map((organization, index) => ({ ...organization, id: response.data.data.items[index].id }))
          } else return []
      },
      async uploadThreads(threads) {
            const _payload = threads.map(thread => {
                const t = {...thread}

                t.contact = this.getObjectId(!this.assignToAdditionalContact ? thread.mainContact : thread.additionalContact)
                t.contactThreadAssignedContacts = []
                if (this.assignToAdditionalContact) {
                    if (this.getObjectId(thread.mainContact) && !Array.isArray(thread.mainContact)) {
                        t.contactThreadAssignedContacts = [{ contact: this.getObjectId(thread.mainContact), part: 100 }]
                    }
                } else {
                    if (this.getObjectId(thread.additionalContact) && !Array.isArray(thread.additionalContact)) {
                        t.contactThreadAssignedContacts = [{ contact: this.getObjectId(thread.additionalContact), part: 100 }]
                    }
                }

                delete t.mainContact
                delete t.additionalContact

                return t
            })
          const response = await axiosIns.post(`1/contacts/threads`, _payload)

          if (response.data?.data?.items?.length) {
              return threads.map((thread, index) => ({ ...thread, id: response.data.data.items[index].id }))
          } else return []
      }
    },
}
</script>

<style lang="scss">
.badge-item {
  transition: all 1s
}

.table-padding-x-0 {
  td, th {
    padding: 0.6rem 0.5rem !important;
  }
}

[dir=ltr] .modal-xl {
  margin-left: 15% !important;
  margin-right: 15% !important;
}
</style>
